import posthog from 'posthog-js'

export default defineNuxtPlugin(() => {
	const { $config } = useNuxtApp()

	const posthogInstance = $config.public.enablePosthog
		? posthog.init('phc_COq8UfhcgMU6s0acP8hHzt4FW7EdIgdsGvCuIQR8a76', {
				api_host: 'https://us.i.posthog.com',
				person_profiles: 'identified_only',
		  })
		: null

	const captureEvent = (event: string, metadata?: any) => {
		if (posthogInstance) {
			posthogInstance.capture(event, metadata)
		}
	}

	const identifyUser = (user: IUser) => {
		if (posthogInstance) {
			posthogInstance.identify(user._id, {
				walletAddress: user.walletAddress,
				hasSubscription: !!user.subscription,
				subscription: user.plan && user.plan.name['en-US'],
				subscriptionCycle: user.subscription?.interval,
				subscriptionStartDate: user.subscription?.createdAt,
				createdAt: user.createdAt,
			})
		}
	}

	if (posthogInstance) {
		const router = useRouter()

		router.afterEach(to => {
			posthogInstance.capture('$pageview', {
				$current_url: to.fullPath,
				$referrer: document.referrer,
			})
		})
	}

	return {
		provide: {
			captureEvent,
			identifyUser,
			posthog,
		},
	}
})
