import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom'
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare'
import { Connection } from '@solana/web3.js'

export default defineNuxtPlugin(() => {
	return {
		provide: {
			connection: new Connection('https://api.mainnet-beta.solana.com'),
			supportedWallets: [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
		},
	}
})
