import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default defineNuxtPlugin(() => {
	return {
		provide: {
			dayjs,
		},
	}
})
