import { default as indexRKWbHmLmj6Meta } from "/usr/src/app/pages/dashboard/index.vue?macro=true";
import { default as subscribersStC9VEYZlVMeta } from "/usr/src/app/pages/dashboard/subscribers.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexRKWbHmLmj6Meta || {},
    component: () => import("/usr/src/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-subscribers",
    path: "/dashboard/subscribers",
    meta: subscribersStC9VEYZlVMeta || {},
    component: () => import("/usr/src/app/pages/dashboard/subscribers.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  }
]