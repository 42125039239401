export default defineNuxtPlugin(() => {
	const { $dayjs } = useNuxtApp()

	const utils = {
		getFormattedExpiringDate(date: string): string {
			const days = $dayjs.utc(date).diff($dayjs.utc(), 'day')
			return days === 0 ? 'Today' : days === 1 ? `${days} day` : `${days} days`
		},
	}

	return {
		provide: {
			utils,
		},
	}
})
