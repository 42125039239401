import payload_plugin_qLmFnukI99 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BwfRt8ewMW from "/usr/src/app/node_modules/vue-sonner/lib/nuxt/runtime/plugin.js";
import api_GFfDXud5Cr from "/usr/src/app/plugins/api.ts";
import dayjs_pGiXRjcsJO from "/usr/src/app/plugins/dayjs.ts";
import node_client_1ZbTG6016B from "/usr/src/app/plugins/node.client.ts";
import posthog_client_fUucxKWhZE from "/usr/src/app/plugins/posthog.client.ts";
import solana_client_AUQlj89kH4 from "/usr/src/app/plugins/solana.client.ts";
import uicons_PjFOL1qquU from "/usr/src/app/plugins/uicons.ts";
import utils_EEhlE5KdlH from "/usr/src/app/plugins/utils.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BwfRt8ewMW,
  api_GFfDXud5Cr,
  dayjs_pGiXRjcsJO,
  node_client_1ZbTG6016B,
  posthog_client_fUucxKWhZE,
  solana_client_AUQlj89kH4,
  uicons_PjFOL1qquU,
  utils_EEhlE5KdlH
]